import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import { Helmet } from 'react-helmet';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import { SectionParagraph } from 'components/SectionParagraph/SectionParagraph';
import { ContainerItems } from 'components/ContainerItems/ContainerItems';
import { Button, ButtonLink, ButtonsWrapper, ButtonText } from 'components/Button/Button';
import { useIntl } from 'gatsby-plugin-intl';
import { useContactQuery } from 'hooks/use-contact-query';
import { ContainerFlex } from '../theme/GlobalStyles';
import Img from 'gatsby-image';
import { useImageQuery } from '../hooks/use-image-query';
import HeroImage from '../components/HeroImage/HeroImage';
import { HeroSectionImage } from '../components/HeroImage/HeroImageStyles';
import * as Styled from '../components/Quote/QuoteStyles';
import QuoteIcon from '../assets/quote.svg';
import { ContentRow, ContentWrapper } from '../components/SectionContent/SectionContentStyles';

const Contact: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const contactData = useContactQuery();
  const dataImage = useImageQuery();

  const { childImageSharp } = dataImage.contact_bg.localFile;
  const { fluid } = contactData[locale].messangericon.localFile.childImageSharp;

  const replaceEmail = contactData && contactData[locale].content;
  const email = replaceEmail.replace(/<\/?p[^>]*>/g, '');
  const replaceMessanger = contactData && contactData[locale].messanger;
  const messanger = replaceMessanger.replace(/<\/?p[^>]*>/g, '');

  return (
    <PageTemplate>
      <HeroImage>
        <HeroSectionImage>
          <Img fluid={childImageSharp.fluid} imgStyle={{ objectPosition: 'top' }} />
        </HeroSectionImage>
        <Styled.QuoteWrapper>
          <QuoteIcon />
          <Styled.QuoteContent notAnimate>{formatMessage({ id: 'quote.title' })}</Styled.QuoteContent>
        </Styled.QuoteWrapper>
      </HeroImage>
      <ContentWrapper>
        <ContentRow top>
          <Helmet htmlAttributes={{ lang: `${locale}` }}>
            <title>Agnieszka Kazmierczyk - Skontaktuj się ze mną</title>
            <meta name="description" content="Some content." />
          </Helmet>
          <SectionHeading>{formatMessage({ id: 'contact.title' })}</SectionHeading>
          <ContainerItems center>
            <SectionParagraph>{formatMessage({ id: 'contact.question' })}</SectionParagraph>
            <ButtonsWrapper>
              <ContainerFlex>
                <Button as={ButtonLink} href={`mailto: ${email}`} aria-label={formatMessage({ id: 'contact.me' })}>
                  <ButtonText>{formatMessage({ id: 'contact.me' })}</ButtonText>
                </Button>
                <SectionParagraph>{formatMessage({ id: 'contact.or' })}</SectionParagraph>
                <Button
                  as={ButtonLink}
                  link
                  href={`${messanger}`}
                  aria-label={formatMessage({ id: 'contact.me' })}
                  target="_blank"
                >
                  <Img fluid={fluid} />
                </Button>
              </ContainerFlex>
            </ButtonsWrapper>
          </ContainerItems>
        </ContentRow>
      </ContentWrapper>
    </PageTemplate>
  );
};

export default Contact;
