import { graphql, useStaticQuery } from 'gatsby';

export const useContactQuery = () => {
  const { wordpressPage } = useStaticQuery(
    graphql`
      query ContactQuery {
        wordpressPage(wordpress_id: { eq: 111 }) {
          acf {
            pl {
              content
              messanger
              messangericon {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            en {
              content
              messanger
              messangericon {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return wordpressPage.acf;
};
